<template>
    <div class="k-page-flex">
        <div class="k-page-title">
            <h1>Arborescences du projet</h1>
            <em
                class="text-secondary"
                v-b-popover.hover.leftbottom.v-secondary="
                    `La création de nouvelles arborescences est disponible dans l'assistant de configuration d'un projet.`
                "
                variant="primary"
            >
                <b-icon-question-circle /> Créer une arborescence
            </em>
        </div>
        <GridCardBasic :data="arbos" empty="Aucune arborescence">
            <template v-slot="{ data }">
                <CardArboAdmin
                    :arbo="data"
                    :project-id="projectId"
                    @update="onArboUpdate(data)"
                    @delete="onArboDelete(data)"
                />
            </template>
        </GridCardBasic>
        <ModalUpdateArbo
            :projectid="projectId"
            :arbo="selectedArbo"
            @saved="onArboUpdated"
        />
        <ModalDanger
            @confirmed="onDeleteConfirmed"
            message="Voulez vous vraiment supprimer cette arborescence?"
            details="L'arborescence et toutes les ressources associées à celle-ci seront définitivement supprimées."
        />
    </div>
</template>

<script>
import GridCardBasic from "@/components/ui/GridCardBasic";
import CardArboAdmin from "@/components/model/arbo/CardArboAdmin";
import ModalUpdateArbo from "@/components/model/arbo/ModalUpdateArbo";
import ModalDanger from "@/components/modal/ModalDanger";

export default {
    components: {
        GridCardBasic,
        CardArboAdmin,
        ModalUpdateArbo,
        ModalDanger,
    },

    data: function() {
        return {
            projectId: this.$route.params.projectId,
            arbos: [],
            selectedArbo: {},
        };
    },

    methods: {
        async fetchArbos() {
            this.arbos = await this.$store.dispatch("project/getArbos", {
                projectId: this.projectId,
            });
        },

        onArboUpdate(arbo) {
            this.selectedArbo = arbo;
            this.$bvModal.show("ModalUpdateArbo");
        },

        onArboDelete(arbo) {
            this.selectedArbo = arbo;
            this.$bvModal.show("ModalDanger");
        },

        async onArboUpdated() {
            this.selectedArbo = {};
            await this.fetchArbos();
        },

        async onDeleteConfirmed() {
            await this.$store.dispatch("project/deleteArbo", {
                projectId: this.projectId,
                arboId: this.selectedArbo.id,
            });
            this.onArboUpdated();
        },
    },

    async beforeMount() {
        await this.fetchArbos();
    },
};
</script>
