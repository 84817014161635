<template>
    <div class="card k-card-2">
        <div class="k-flex-row">
            <div>
                <small class="text-kalkin-1">Nom</small>
                <div>
                    <b>{{ arbo.name }}</b>
                </div>
            </div>
            <div>
                <b-button
                    class="m-1"
                    size="xs"
                    variant="outline-secondary"
                    @click="$emit('update')"
                >
                    <b-icon-pencil />
                </b-button>
                <b-button
                    class="m-1"
                    size="xs"
                    variant="outline-danger"
                    @click="$emit('delete')"
                >
                    <b-icon-trash />
                </b-button>
            </div>
        </div>

        <div class="k-flex-row k-flex-fill mt-2">
            <div>
                <small class="text-kalkin-1">Modèle</small>
                <div>
                    {{ getArboTemplate(arbo) }}
                </div>
            </div>
            <div>
                <small class="text-kalkin-1">Structure</small>
                <div>
                    {{ getArboStructure(arbo) }}
                </div>
            </div>
        </div>
        <div>
            <small class="text-kalkin-1">Nombre de menus</small>
            <div>{{ arbo.menuCount }}</div>
        </div>

        <div>
            <small class="text-kalkin-1">Langues</small>

            <div>
                <template v-for="lang in getArboLangs(arbo)">
                    <span
                        class="mx-1"
                        :class="`flag:${lang.id}`"
                        :key="lang.id"
                        v-b-tooltip.hover
                        :title="lang.name"
                    />
                </template>
            </div>
        </div>

        <div>
            <small class="text-kalkin-1">
                Editer le contenu de l'arborescence
            </small>
            <div class="k-flex-row k-flex-fill">
                <b-button
                    size="xs"
                    variant="kalkin-1"
                    @click="onGoEditArbo(arbo.id)"
                >
                    Structure
                </b-button>
                <b-button
                    size="xs"
                    variant="kalkin-1"
                    @click="onGoEditData(arbo.id)"
                >
                    Données
                </b-button>
                <b-dropdown size="xs" variant="kalkin-1" text="Style" right>
                    <template v-if="arbo.ApplicationConfigs.length">
                        <b-dropdown-item
                            v-for="c in arbo.ApplicationConfigs"
                            :key="c.id"
                            @click="onGoEditStyle(arbo.id, c.id)"
                        >
                            {{ c.name }}
                        </b-dropdown-item>
                    </template>
                    <template v-else>
                        <b-dropdown-item disabled>
                            <i>Aucun configuration associée</i>
                        </b-dropdown-item>
                    </template>
                </b-dropdown>
            </div>
        </div>

        <div>
            <div>
                <small class="text-kalkin-1">Date de création</small>
                <div>Le {{ arbo.createdAt | dateFromISO }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { getArboTemplate, getArboStructure, getArboLangs } from "@/model/arbo";

export default {
    props: {
        arbo: Object,
        projectId: { type: [String, Number], required: true },
    },

    methods: {
        getArboTemplate,
        getArboStructure,
        getArboLangs,

        onGoEditArbo(id) {
            this.$router.push({
                name: "AdminEditArboStructure",
                params: {
                    arboId: id,
                    projectId: this.projectId,
                },
            });
        },
        onGoEditData(id) {
            this.$router.push({
                name: "AdminEditArboData",
                params: {
                    arboId: id,
                    projectId: this.projectId,
                },
            });
        },
        onGoEditStyle(id, config) {
            this.$router.push({
                name: "AdminEditArboStyle",
                params: {
                    arboId: id,
                    configId: config,
                    projectId: this.projectId,
                },
            });
        },
    },
};
</script>
